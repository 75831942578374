import React from 'react';
import UserPage from './UserPage';
import ChatsPage from './ChatsPage';
import HomePage from './HomePage';
import MessagesPage from './MessagesPage';
import { DashboardContext } from '../../pages/Dashboard';


export default function Content() {

    const { pageSelected } = React.useContext(DashboardContext)

    return (
        <div
            className='w-full h-full'
        >
            { pageSelected === 'users' ? 
                <UserPage />
            :
                pageSelected === 'chats' ? 
                <ChatsPage />
            :
                pageSelected === 'dashboard' ? 
                <HomePage />
            :
                pageSelected === 'messages' ?
                <MessagesPage />
            :
                <h1>Page not implemented yet</h1>
            }
       </div>
    )
}