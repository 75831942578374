import React from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

// Reusable component for pricing card wrapper
function PriceWrapper({ children }) {
  return (
    <Box
      mb={4}
      shadow="base"
      borderWidth="1px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

// Main Pricing Component
export default function ThreeTierPricing() {
  return (
    <div
      className="bg-white py-16 px-6 md:px-12 text-center flex flex-col gap-6"
    >
      {/* Header */}
      <div
        className="flex flex-col text-3xl md:text-4xl text-gray-800 gap-4"
      >
        <text
          className="text-3xl md:text-4xl text-gray-800"
        >
          Plans that fit your need
        </text>
        <text
          className="text-lg text-gray-600"
        >
          Use our platform entirely for free while we are in beta!
        </text>
      </div>

      {/* Pricing Cards */}
      <div
        className="flex flex-col md:flex-row items-center justify-center gap-4 lg:gap-8 py-2"
      >
        {/* Personal Plan */}
        <div
          className="flex flex-col items-center justify-center border-2 border-orange-300 rounded-xl shadow-md h-96"
        >
          <div 
            className="relative"
          >
            <div
              className="absolute top-[-16px] left-1/2 transform translate-x-[-50%]"
            >
              <text
                className="text-xs bg-orange-300 text-white px-3 py-1 font-semibold rounded-xl"
              >
                Free beta!
              </text>
            </div>
            <div 
              className="flex flex-col items-center justify-center py-4 px-12 gap-2"
            >
              <text
                className="text-2xl font-bold"
              >
                Personal
              </text>
              <div
                className="flex flex-row items-center justify-center gap-1"
              justifyContent="center">
                <text
                  className="text-5xl font-bold"
                >
                  0
                </text>
                <text 
                  className="text-3xl font-semibold text-gray-700"
                >
                  €
                </text>
                <text 
                  className="text-3xl font-semibold text-gray-500"
                >
                  /month
                </text>
              </div>
            </div>
            <div
              className="py-4 rounded-b-xl flex flex-col gap-8"
            >
              <List.Root gap="2" variant="plain" textAlign="start" px={12}>
                <List.Item>
                    <List.Indicator asChild color="green.500">
                        <FaCheckCircle />
                    </List.Indicator>
                    Full access to SparkAI
                </List.Item>
                <List.Item>
                    <List.Indicator asChild color="green.500">
                        <FaCheckCircle />
                    </List.Indicator>
                  Full access to Focus!
                </List.Item>
                <List.Item>
                    <List.Indicator asChild color="green.500">
                        <FaCheckCircle />
                    </List.Indicator>
                    Full access to SparkQuery
                </List.Item>
              </List.Root>
              <div
                className="flex w-full justify-center rounded-xl"
              >
                <button
                  className="w-9/12 py-2 rounded-xl bg-orange-300 text-white font-semibold hover:bg-orange-200"
                onClick={() => window.open("https://ederspark.com/?page=platform", "_self")}>
                    Try it now!
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Enterprise */}
        <div
          className="flex flex-col items-center justify-center border-2 border-blue-400 rounded-xl shadow-md h-96"
        >
          <div 
            className="relative"
          >
            <div
              className="absolute top-[-16px] left-1/2 transform translate-x-[-50%]"
            >
              <text
                className="text-xs bg-blue-400 text-white px-3 py-1 font-semibold rounded-xl"
              >
                Volume discount!
              </text>
            </div>
            <div 
              className="flex flex-col items-center justify-center py-4 px-12 gap-2"
            >
              <text
                className="text-2xl font-bold"
              >
                Enterprise
              </text>
              <div
                className="flex flex-row items-center justify-center gap-1"
              justifyContent="center">
                <text
                  className="text-5xl font-bold"
                >
                  Custom
                </text>
              </div>
            </div>
            <div
              className="py-4 rounded-b-xl flex flex-col gap-8"
            >
              <List.Root gap="2" variant="plain" textAlign="start" px={12}>
                <List.Item>
                    <List.Indicator asChild  color="blue.400">
                        <FaCheckCircle />
                    </List.Indicator>
                    Full access to SparkAI
                </List.Item>
                <List.Item>
                    <List.Indicator asChild  color="blue.400">
                        <FaCheckCircle />
                    </List.Indicator>
                  Full access to Focus!
                </List.Item>
                <List.Item>
                    <List.Indicator asChild color="blue.400">
                        <FaCheckCircle />
                    </List.Indicator>
                    Full access to SparkQuery
                </List.Item>
              </List.Root>
              <div
                className="flex w-full justify-center rounded-xl"
              >
                <button
                  className="w-9/12 py-2 rounded-xl bg-blue-400 text-white font-semibold hover:bg-blue-200"
                onClick={() => window.open("https://ederspark.com/?page=Contact", "_self")}>
                    Contact us!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
