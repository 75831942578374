import { Kbd } from "@chakra-ui/react"


export default function BaseSidebarMenuButton({ Icon, text, pageSelected, setPageSelected, kbd }) {
    return (
        <button
            className={`
                sidebarMenuButton
                ${pageSelected === text.toLowerCase() && 'sidebarMenuButtonActive'}
            `}
            onClick={() => setPageSelected(text.toLowerCase())}
        >
            <div
                className="flex flex-row items-center justify-between gap-2 px-4"
            >
                <div
                    className="flex flex-row justify-center items-center gap-1"
                >
                    <Icon className="h-6 w-6  hover:text-orange-500" />
                    <p
                        className="text-center  hover:text-orange-500 font-semibold"
                        >
                        {text}
                    </p>
                </div>
                <Kbd>{kbd}</Kbd>
            </div>
        </button>
    )
}