import React from 'react';
import ThreeUsersIcon from '../../icons/ThreeUsersIcon.png';
import { ChevronRightIcon, ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/react/20/solid';
import { DashboardContext } from '../../pages/Dashboard';
import { Skeleton } from '@mui/joy';

export default function BaseCard({number = 52,
    percentage = 25,
    label = 'New users this month',
    targetPage = 'users',
    icon = ThreeUsersIcon,
    isLoading = false 
}) {
    const [ isHovered, setIsHovered ] = React.useState(false)
    const { setPageSelected } = React.useContext(DashboardContext)

    return (
        <>
        {isLoading ? 
            <Skeleton variant='rectangular' width={384} height={128} 
                className='rounded-lg shadow-md hover:shadow-lg hover:scale-105 transition-all duration-300'
            />
            :
            <div
            className={`h-32 w-full sm:w-96 md:w-72 lg:w-[450px]
                bg-white rounded-lg shadow-md
                hover:shadow-lg hover:scale-105 transition-all duration-300
                hover:bg-gray-50 hover:border-2 hover:border-orange-200
                hover:cursor-pointer flex flex-col
                `}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => setPageSelected(targetPage)}
                >
            <div
                className={
                    `
                    h-full w-full hover:h-[75%] transition-all duration-300
                    flex flex-row justify-center items-center gap-4`
                }
                >
                <div>
                    <img
                        src={icon}
                        alt="Icon"
                        className='w-16 h-16 mx-auto'
                        />
                </div>
                <div>
                    <p
                        className='text-sm text-gray-400'
                        >
                        {label}
                    </p>
                    <h1
                        className='text-2xl font-semibold text-gray-800'
                        >
                        {number}
                    </h1>
                </div>
                <div
                    className='flex flex-col justify-center items-center'
                    >
                    <span
                        className={` cardPercentage
                            ${percentage > 0 ? 'cardPercentageIncrease' : 'cardPercentageDecrease'}    
                            `}
                            >
                        <p>
                            {percentage > 0 ? `+${percentage}%` : `${percentage}%`}
                        </p>    
                        {percentage === 0 ? null : percentage > 0 ? <ArrowTrendingUpIcon className='h-4 w-4 text-green-500' /> : <ArrowTrendingDownIcon className='h-4 w-4 text-red-500' />}
                    </span>
                </div>
            </div>
            <div
                className={
                    `h-auto w-full
                    transition-all duration-300
                    ${isHovered ? 'flex flex-col justify-center items-center' : 'hidden'}
                    `
                }
                >
                <button
                    className='w-full h-full'
                    >
                    <div
                        className='flex flex-row justify-center items-center gap-1'
                        >
                        <p
                            className='text-sm text-orange-500 font-semibold'
                            >
                            View more
                        </p>
                        <ChevronRightIcon
                            className='h-4 w-4 text-orange-500'
                            />
                    </div>
                </button>
            </div>
        </div>
        }
    </>
    )
}