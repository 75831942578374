import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
} from "@chakra-ui/react";
import {
  MenuRoot,
  MenuTrigger,
  MenuContent,
  MenuItem,
} from "@chakra-ui/react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { SiGithub, SiLinkedin, SiX } from 'react-icons/si'
import { AiFillInstagram } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

// Import Stack from MUI Joy as MUIStack
import { Stack as MUIStack } from "@mui/joy";

import EdersparkLogo from "../assets/ederspark-logo.png";
import SparkAILogo from "../assets/sparkai_new_only_logo.png";
import FocusLogo from "../assets/focus_only_logo_upscaled.png";
import SparkQueryLogo from "../assets/sparkquery_only_logo_upscaled.png";

import { BeakerIcon } from "@heroicons/react/24/solid";

import { Typography } from "@mui/joy";


const Navbar = ({activeLink, setActiveLink}) => {
  const [isOpen, setIsOpen] = useState(false);


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const setLink = (link) => {
    setActiveLink(link);
  }

  // Menu items
  const pages = [
    { name: "Home", path: "/home" },
    { name: "Tools", path: "/" },
    { name: "Contact", path: "/contact" },
    { name: "Platform", path: "/platform" }
  ];

  return (
    <div
      className=""
    >
      {/* Navbar */}
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding="1rem 1.5rem"
        bg="white"
        color="black"
        boxShadow="md"
        position="sticky"
        top={0}
        zIndex={1000}
      >
        {/* Logo */}
        <img src={EdersparkLogo} alt="Ederspark Logo" width="150" height="50" />

        {/* Desktop Navigation Links */}
        <Flex
          as="ul"
          display={{ base: "none", md: "flex" }}
          gap="1.5rem"
          listStyleType="none"
          align="center"
          position="relative"
        >
          {pages.map((page) =>
            page.name === "Tools" ? (
              <MenuRoot key={page.name}>
                {/* Hoverable Trigger (it appears when hovered) */}
                <MenuTrigger>
                    <Text
                        cursor="pointer"
                        fontWeight={activeLink === page.name ? "bold" : "normal"}
                        color={activeLink === page.name ? "orange.400" : "gray.700"}
                        _hover={{ color: "orange.400" }}
                    >
                        <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                            <Typography level="body-md">{page.name}</Typography>
                            <IoIosArrowDown />
                        </MUIStack>
                    </Text>
                </MenuTrigger>

                {/* Dropdown Menu */}
                <MenuContent
                  bg="white"
                  boxShadow="lg"
                  py="2"
                  position="absolute"
                  top="100%"
                  left="0"
                  mt="0.5rem"
                  borderRadius="md"
                  minWidth="200px"
                >
                  <Stack spacing="2" align="start">
                    <MenuItem
                      as="div"
                      bg="white"
                      cursor="pointer"
                      px="4"
                      py="2"
                      _hover={{ bg: "orange.100", color: "orange.500" }}
                      onClick={() => window.open("https://sparkai.ederspark.com/platform", "_blank")}
                    >
                        <Stack spacing="2" align="start">
                            <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                                <img src={SparkAILogo} alt="SparkAI Logo" style={{width: 15, height: 18}} />
                                <Typography level="title-md">SparkAI</Typography>
                            </MUIStack>
                            <Typography level="body-sm">A research assistant that elevates your research productivity and efficiency to the next level.</Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                      as="div"
                      bg="white"
                      cursor="pointer"
                      px="4"
                      py="2"
                      _hover={{ bg: "orange.100", color: "orange.500" }}
                      onClick={() => window.open("https://sparkai.ederspark.com/platform", "_blank")}
                    >
                        <Stack spacing="2" align="start">
                            <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                                <img src={FocusLogo} alt="Focus Logo" style={{width: 20, height: 20}} />
                                <Typography level="title-md">Focus</Typography>
                            </MUIStack>
                            <Typography level="body-sm">Take SparkAI to the next level with Focus, a platform that helps you focus on what's important.</Typography>
                        </Stack>
                    </MenuItem>
                    <MenuItem
                        as="div"
                        bg="white"
                        cursor="pointer"
                        px="4"
                        py="2"
                        _hover={{ bg: "orange.100", color: "orange.500" }}
                        onClick={() => window.open("https://sparkai.ederspark.com/platform", "_blank")}
                    >
                        <Stack spacing="2" align="start">
                            <MUIStack direction="row"  spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                                <img src={SparkQueryLogo} alt="SparkQuery Logo" style={{width: 20, height: 20}} />
                                <Typography level="title-md">SparkQuery</Typography>
                            </MUIStack>
                            <Typography level="body-sm">A powerful tool that helps you query scientific articles in a natural and organic way.</Typography>
                        </Stack>
                    </MenuItem>
                  </Stack>
                </MenuContent>
              </MenuRoot>
            ) : (
              <Text
                key={page.name}
                as="li"
                cursor="pointer"
                onClick={() => setLink(page.name)}
                fontWeight={activeLink === page.name ? "bold" : "normal"}
                color={activeLink === page.name ? "orange.400" : "gray.700"}
                _hover={{ color: "orange.400" }}
              >
                {page.name === "Platform" ?
                    <div
                        className="flex flex-row items-center"
                    >
                        <BeakerIcon className="h-5 w-5" />
                        <span className="ml-2">
                            {page.name}
                        </span>
                    </div>
                :
                    page.name
                }

              </Text>
            )
          )}
        </Flex>
        <Flex align="center" gap="4">

                {/* Social Media Icons */}
                <div 
                  className="hidden md:block md:ml-auto md:gap-3"
                gap="3">
                    <a
                    href="https://www.linkedin.com/company/ederspark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="LinkedIn"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <FaLinkedin />
                    </IconButton>
                    </a>
                    <a
                    href="https://x.com/EderSpark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="Twitter"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <SiX />
                    </IconButton>
                    </a>
                    <a
                    href="https://www.tiktok.com/@ederspark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="TikTok"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <FaTiktok />
                    </IconButton>
                    </a>
                    <a
                    href="https://www.instagram.com/ederspark/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="Instagram"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <AiFillInstagram />
                    </IconButton>
                    </a>
                </div>
            </Flex>

        {/* Hamburger Icon (Mobile Only) */}
        <IconButton
          display={{ base: "block", md: "none" }}
          onClick={toggleMenu}
          variant="ghost"
          aria-label="Toggle Navigation"
          ml="auto"
        >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </IconButton>
      </Flex>

      {/* Mobile Navigation Menu */}
      {isOpen && (
        <Box
          as="ul"
          bg="white"
          boxShadow="md"
          padding="1rem"
          listStyleType="none"
        >
          <Stack spacing="4">
            {pages.map((page) =>
              page.name === "Tools" ? (
                <MenuRoot key={page.name}>
                  <MenuTrigger>
                    <Text
                      cursor="pointer"
                      textAlign={{ base: "left", md: "center" }}
                      fontWeight={activeLink === page.name ? "bold" : "normal"}
                      color={activeLink === page.name ? "orange.400" : "gray.700"}
                      _hover={{ color: "orange.400" }}
                    >
                      {page.name}
                    </Text>
                  </MenuTrigger>
                  <MenuContent>
                    <Stack spacing="2" align="start">
                      <MenuItem
                        as="div"
                        bg="white"
                        cursor="pointer"
                        _hover={{ bg: "orange.100", color: "orange.500" }}
                        onClick={() => window.open("https://sparkai.ederspark.com/sparkai", "_blank")}
                      >
                        <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                            <img src={SparkAILogo} alt="SparkAI Logo" style={{width: 15, height: 18}} />
                            <Typography level="title-md">SparkAI</Typography>
                            <Typography level="body-sm">Assistant with context of +200M papers</Typography>
                        </MUIStack>
                      </MenuItem>
                      <MenuItem
                        as="div"
                        bg="white"
                        cursor="pointer"
                        _hover={{ bg: "orange.100", color: "orange.500" }}
                        onClick={() => window.open("https://sparkai.ederspark.com/focus", "_blank")}
                      >
                        <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                            <img src={FocusLogo} alt="Focus Logo" style={{width: 20, height: 20}} />
                            <Typography level="title-md">Focus</Typography>
                            <Typography level="body-sm">Focus only on one paper, ask anything</Typography>
                        </MUIStack>
                      </MenuItem>
                      <MenuItem
                        as="div"
                        bg="white"
                        cursor="pointer"
                        _hover={{ bg: "orange.100", color: "orange.500" }}
                        onClick={() => window.open("https://sparkai.ederspark.com/sparkquery", "_blank")}
                      >
                        <MUIStack direction="row" spacing={1} sx={{alignItems: "center", justifyContent: "center"}}>
                            <img src={SparkQueryLogo} alt="SparkQuery Logo" style={{width: 20, height: 20}} />
                            <Typography level="title-md">SparkQuery</Typography>
                            <Typography level="body-sm">Next-gen search engine. Query in a natural way</Typography>
                        </MUIStack>
                      </MenuItem>
                    </Stack>
                  </MenuContent>
                </MenuRoot>
              ) : (
                <Text
                  key={page.name}
                  as="li"
                  cursor="pointer"
                  onClick={() => setLink(page.name)}
                  fontWeight={activeLink === page.name ? "bold" : "normal"}
                  color={activeLink === page.name ? "orange.400" : "gray.700"}
                  _hover={{ color: "orange.400" }}
                >
                  {page.name}
                </Text>
              )
            )}
          </Stack>
        </Box>
      )}
    </div>
  );
};

export default Navbar;
