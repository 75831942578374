
import React from "react";
import Navbar from "../pages/Navbar.jsx";
import { Box, Typography, Button } from "@mui/joy";
import HeroSection from "../components/HeroSection.jsx"; 
import TrustedLogos from "../components/TrustedLogosSection.jsx";
import HowItWorksSection from "../components/HowItWorksSection.jsx";
import KeyFeatures from "../components/KeyFeaturesSection.jsx";
import WhyChooseUs from "../components/WhyChooseUsSection.jsx";
import Footer from "../components/Footer.tsx";
import ThreeTierPricing from "../components/PricingSection.jsx";
import Terms from "../components/Terms.jsx";


const TermsPage = ({ activeLink, setActiveLink }) => {
  return (
    <Box
    bg="white"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        <Terms />
      <Footer />
    </Box>
  );
};

export default TermsPage;
