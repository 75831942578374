/**
 * This function will recieve a list of data and a month and will return the number of data that were created in that month
 * and the difference in percentage compared to the previous month
 * 
 * @param {Array} data
 * @param {String} month
 * 
 * @returns {Object}
 */
export const getNewDataThisMonth = (data, month) => {
    const dataThisMonth = data.filter(data => {
        let dataMonth = null
        if (data.created_at) {
            dataMonth = new Date(data.created_at).getMonth()
        } else if (data.started_at) {
            dataMonth = new Date(data.started_at).getMonth()
        } else {
            dataMonth = new Date(data.messaged_at).getMonth()
        }
        return dataMonth === month
    })
    const dataLastMonth = data.filter(data => {
        let dataMonth = null
        if (data.created_at) {
            dataMonth = new Date(data.created_at).getMonth()
        } else if (data.started_at) {
            dataMonth = new Date(data.started_at).getMonth()
        } else {
            dataMonth = new Date(data.messaged_at).getMonth()
        }
        return dataMonth === month - 1
    })
    const percentage = (dataThisMonth.length / dataLastMonth.length) * 100
    return {
        number: dataThisMonth.length,
        percentage: Math.round(percentage)
    }
}