import React from 'react';
import { DashboardContext } from '../../pages/Dashboard';
import Content from './Content';

  

export default function Layout() {

    const { isSidebarOpen } = React.useContext(DashboardContext)
    const [ screenWidth, setScreenWidth ] = React.useState(window.innerWidth)
    
    return (
        <div
            className={`
                w-full h-full py-10 px-3 md:py-16 md:px-16 transition-all duration-300
                ${isSidebarOpen ? `ml-[${screenWidth}px] md:ml-60` : 'ml-12'}
                ${isSidebarOpen ? `w-[calc(100%-${screenWidth}px)] md:w-[calc(100%-240px)]` : 'w-[calc(100%-60px)]'}
                `}
        >
            <Content />
        </div>
        
    )
}