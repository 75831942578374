import React, { useState } from "react";
import axios from "axios";


export default function ContactFormWithMaterialJoy() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    let validationErrors = {};

    if (!formData.name) validationErrors.name = "Name is required.";
    if (!formData.email) {
      validationErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = "Email is invalid.";
    }
    if (!formData.message) validationErrors.message = "Message is required.";
    if (formData.subject === "") {
      validationErrors.subject = "Subject is required.";
    }

    return validationErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    console.log("Form Data Submitted: ", formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      alert("Message Sent Successfully!");
      axios.post("http://localhost:8000/api/send-mail", 
        {
          to: "jose.flores@ederspark.com",
          subject: `New message from ${formData.email} | ${formData.subject}`,
          text: `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`,
        }
      ).then((res) => {
      }).catch((err) => {
      });
    }
  };

  return (
    <div
      className="bg-white w-full flex justify-center items-center py-6"
    >
      <div
        className="w-full md:w-3/4 lg:w-8/12 xl:w-6/12 p-4 bg-white rounded-lg shadow-md border-2 border-orange-400"
      >
        <form
          className="text-center flex flex-col gap-4"
          onSubmit={handleSubmit}
        >
          {/* Heading */}
          <text
            className="text-orange-400 font-semibold text-2xl mb-2 text-center"
          >
            Contact Us
          </text>

          {/* Form Fields */}
          <div
            className="flex flex-col gap-3"
          >
            {/* Name Field */}
            <div>
              <input
                className="w-full p-2 rounded-lg border-2 border-gray-300"
                name="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                error={!!errors.name}
              />
              {errors.name && (
                <text
                  className="text-danger text-sm mt-1"
                >
                  {errors.name}
                </text>
              )}
            </div>

            {/* Email Field */}
            <div>
              <input
                className="w-full p-2 rounded-lg border-2 border-gray-300"
                name="email"
                type="email"
                placeholder="Enter your email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
              />
              {errors.email && (
                <text
                  className="text-danger text-sm mt-1"
                >
                  {errors.email}
                </text>
              )}
            </div>

            {/* Place here a select field for the subject of the message */}
            
            <div>
              <select
                className="w-full p-2 rounded-lg border-2 border-gray-300 text-gray-400"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                error={!!errors.subject}
                >
                <option value="">Purpose of the message</option>
                {/* Add option for institutions wanting to buy licenses */}
                <option value="license-purchase">License Purchase (Institutions/Enterprises)</option>
                <option value="general">General Inquiry</option>
                <option value="support">Support</option>
                <option value="feedback">Feedback</option>
              </select>
              {errors.subject && (
                <text
                  className="text-danger text-sm mt-1"
                >
                  {errors.subject}
                </text>
              )}
            </div>

            {/* Message Field */}
            <div>
              <textarea
                className="w-full p-2 rounded-lg border-2 border-gray-300"
                name="message"
                placeholder="Enter your message"
                rows={6}
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
              />
              {errors.message && (
                <text
                  level="body2"
                  color="danger"
                  mt={1}
                >
                  {errors.message}
                </text>
              )}
            </div>

            {/* Submit Button */}
            <button
              className="bg-orange-400 text-white font-semibold py-2 rounded-lg hover:bg-orange-200"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
