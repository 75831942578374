import React from "react";
import { Box, SimpleGrid, VStack, Heading, Text } from "@chakra-ui/react";
import { FaClock, FaChartLine, FaSyncAlt, FaChartPie } from "react-icons/fa";

const WhyChooseUs = () => {
  return (
    <div
      className="flex flex-col items-center justify-center gap-8 bg-gray-50 text-center w-full md:pt-8 pb-24 px-6 rounded-xl shadow-md"
    >
      {/* Section Title */}
      <text
        className="text-3xl md:text-4xl text-gray-800"
        // add an anchor link to the section
        id="why-choose-us"
      >
        Why choose us
      </text>

      {/* Grid of Features */}
      <div
        className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4 px-8"
      >        
      {/* Feature 1 */}
        <VStack spacing="4" textAlign="center">
          <FaClock size="40px" color="#ED8936" />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Save Time
          </Heading>
          <Text fontSize="md" color="gray.600">
            Reduce hours spent on research with AI-powered tools that streamline your workflow.
          </Text>
        </VStack>

        {/* Feature 2 */}
        <VStack spacing="4" textAlign="center">
          <FaChartLine size="40px" color="#ED8936" />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Increase Productivity
          </Heading>
          <Text fontSize="md" color="gray.600">
            Focus on impactful discoveries and achieve more with enhanced efficiency.
          </Text>
        </VStack>

        {/* Feature 3 */}
        <VStack spacing="4" textAlign="center">
          <FaChartPie size="40px" color="#ED8936" />
          <Heading as="h3" fontSize="xl" color="gray.700">
            AI Explained
          </Heading>
          <Text fontSize="md" color="gray.600">
            Our trustworthy AI tools are designed to reduce hallucinations and increase transparency.
          </Text>
        </VStack>

        {/* Feature 4 */}
        <VStack spacing="4" textAlign="center">
          <FaSyncAlt size="40px" color="#ED8936" />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Continuously Updated
          </Heading>
          <Text fontSize="md" color="gray.600">
            Stay ahead with the latest research trends and updates to our AI algorithms.
          </Text>
        </VStack>
      </div>
    </div>
  );
};

export default WhyChooseUs;
