import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Skeleton } from '@mui/joy';


const columns = [
    { field: 'conversation_id', headerName: 'Chat ID', width: 90 },
    { field: 'user_id', headerName: 'User ID', width: 150, editable: false },
    { field: 'conversation_name', headerName: 'Chat Name', width: 350, editable: false },
    { 
        field: 'chat_type',
        headerName: 'Chat Type',
        width: 150,
        editable: false,
        valueGetter: (value, row) => {
            return row?.chat_type === "chat" ? "SparkAI" : "Focus"
        }
    },
    { 
        field: 'created_at', 
        headerName: 'Created At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.started_at ? new Date(row.started_at).toUTCString() : null
        }
    },
    { 
        field: 'updated_at', 
        headerName: 'Updated At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.updated_at ? new Date(row.updated_at).toUTCString() : null
        } 
    }
  ];

export default function ChatsPage() {
    const [rows, setRows] = React.useState([])
    const [isTableLoading, setIsTableLoading] = React.useState(true)

    React.useEffect(() => {
        const fetchData = async () => {
            // Add process.env.API_KEY to the fetch URL header x-api-key
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, { 
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
            const data = await response.json()
            const rows = data.map((row, index) => {
                return {
                    id: index,
                    ...row
                }
            })
            console.log("Rows: ", rows)
            setRows(rows)
            setIsTableLoading(false)
        }
        fetchData()
    }, [])

    return (
        <>
        { isTableLoading ?
            <div className='w-full h-full flex justify-center items-center'>
                <Skeleton variant="rectangular" width={1200} height={600} />
            </div>
            : 
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },
                sorting: {
                    sortModel: [{ field: 'updated_at', sort: 'desc' }], // Replace 'id' with the name of the column
                },
            }}
            pageSizeOptions={[15]}
            checkboxSelection
            disableRowSelectionOnClick
            />
        }
        </>
    )
}