import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Skeleton } from '@mui/joy';


const columns = [
    { field: 'user_id', headerName: 'User ID', width: 90 },
    { field: 'mail', headerName: 'Email', width: 200, editable: false },
    { field: 'name', headerName: 'First Name', width: 150, editable: false },
    { field: 'subscribed', headerName: 'Subscribed', width: 120, type: 'boolean', editable: false },
    { 
        field: 'created_at', 
        headerName: 'Created At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.created_at ? new Date(row.created_at).toUTCString() : null
        }
    },
    { 
        field: 'updated_at', 
        headerName: 'Updated At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.updated_at ? new Date(row.updated_at).toUTCString() : null
        }
    }
  ];

export default function UserPage() {
    const [rows, setRows] = React.useState([])
    const [isTableLoading, setIsTableLoading] = React.useState(true)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
            const data = await response.json()
            const rows = data.map((row, index) => {
                return {
                    id: index,
                    ...row
                }
            })
            console.log("Rows: ", rows)
            setRows(rows)
            setIsTableLoading(false)
        }
        fetchData()
    }, [])

    return (
        <div
            className='w-11/12 h-full md:w-[98%] lg:w-full'
        >
        { isTableLoading ?
            <div className='w-full h-full flex justify-center items-center'>
                <Skeleton variant="rectangular" width={1200} height={600} />
                </div>
            :
            
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },
                sorting: {
                    sortModel: [{ field: 'user_id', sort: 'desc' }], // Replace 'id' with the name of the column
                },
            }}
            pageSizeOptions={[15]}
            checkboxSelection
            disableRowSelectionOnClick
            />
        }
        </div>
    )
}