import React from "react";
import { Box, Typography, Button, Stack } from "@mui/joy";
import { motion } from "framer-motion"; // For animations
import Navbar from "./Navbar.jsx";
import Footer from "../components/Footer.tsx";

const MotionTypography = motion(Typography); // Motion applied to Typography

const Landing = ({ activeLink, setActiveLink }) => {
  return (
    <div
      className="bg-white"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />

      {/* Main Content */}
      <div
        className="flex flex-col justify-center items-center min-h-[80vh] px-4 bg-white text-center"
      >
        {/* Heading with Highlight */}
        <div
          className="flex flex-col space-y-4 items-center"
        >
          <MotionTypography
            level="h1"
            color="black"
            align="center"
            fontSize="3rem"
            fontWeight="bold"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{ color: "black" }}
          >
            Welcome to{" "}
            <text
              className="text-orange-400 underline font-bold"
            >
              EderSpark
            </text>{" "}
            AI Platform
          </MotionTypography>

          {/* Subheading */}
          <MotionTypography
            level="body-md"
            color="gray.600"
            align="center"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            style={{ color: "black" }}
          >
            <text
              className="text-orange-400 underline font-bold"
            >
              Enhance
            </text>{" "}
            your research process with our{" "}
            <text
              className="text-orange-400 underline font-bold"
            >
              AI-powered
            </text>{" "}
            tools.
          </MotionTypography>

          {/* Call-to-Action Buttons */}
          <Stack
            direction={{ base: "column", md: "row" }}
            spacing={2}
            mt={4}
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <button
              className="text-white bg-orange-400 hover:bg-orange-200 px-4 py-2 w-48 rounded-md shadow-lg font-bold mb-4 md:mb-0"
              onClick={() => window.open("https://sparkai.ederspark.com/signup", "_self")}
            >
              Sign Up
            </button>
            <button
              className="text-orange-400 bg-white hover:bg-gray-100 px-4 py-2 w-48 rounded-md shadow-lg font-bold border-2 border-orange-400"
              onClick={() => window.open("https://sparkai.ederspark.com/signin", "_self")}
            >
              Sign In
            </button>
          </Stack>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Landing;
