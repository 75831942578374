import React from 'react';

import { DashboardContext } from '../../pages/Dashboard';
import { ChevronLeftIcon, ChevronRightIcon, UserIcon, ChatBubbleBottomCenterIcon, HomeIcon } from '@heroicons/react/20/solid';
import BaseSidebarMenuButton from './BaseSidebarMenuButton';
import EderSparkLogo from '../../assets/ederspark-logo.png';


function SidebarMenu() {
    const { isSidebarOpen } = React.useContext(DashboardContext)
    const { pageSelected, setPageSelected } = React.useContext(DashboardContext)

    // Keybindings for the sidebar menu
    React.useEffect(() => {
        const handleKeydown = (e) => {
            if (e.key === 'd') {
                setPageSelected('dashboard')
            } else if (e.key === 'u') {
                setPageSelected('users')
            } else if (e.key === 'c') {
                setPageSelected('chats')
            } else if (e.key === 'm') {
                setPageSelected('messages')
            }
        }
        window.addEventListener('keydown', handleKeydown)
        return () => window.removeEventListener('keydown', handleKeydown)
    }, [setPageSelected])

    return (
        <div
            className="flex flex-col gap-4 items-center justify-center transition-all duration-300"
            hidden={!isSidebarOpen}
        >
            <BaseSidebarMenuButton
                Icon={HomeIcon}
                text="Dashboard"
                pageSelected={pageSelected}
                setPageSelected={setPageSelected}
                kbd="D"
            />
            <BaseSidebarMenuButton
                Icon={UserIcon}
                text="Users"
                pageSelected={pageSelected}
                setPageSelected={setPageSelected}
                kbd="U"
            />
            <BaseSidebarMenuButton
                Icon={ChatBubbleBottomCenterIcon}
                text="Chats"
                pageSelected={pageSelected}
                setPageSelected={setPageSelected}
                kbd="C"
            />
            <BaseSidebarMenuButton
                Icon={ChatBubbleBottomCenterIcon}
                text="Messages"
                pageSelected={pageSelected}
                setPageSelected={setPageSelected}
                kbd="M"
            />
        </div>
    )
}

export default function Sidebar() {
    const { isSidebarOpen, setIsSidebarOpen } = React.useContext(DashboardContext)
    const [sidebarWidth, setSidebarWidth] = React.useState(240)

    const handleResize = () => {
        calcSidebarWidth()
    }

    const calcSidebarWidth = () => {
        if (!isSidebarOpen) {
            setSidebarWidth(48);
        } else {
            if (window.innerWidth < 500) {
                setSidebarWidth(window.innerWidth)
            } else {
                setSidebarWidth(240)
            }
        }
    }

    React.useEffect(() => {
        calcSidebarWidth()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [isSidebarOpen])


    return (
        <>
            <div
                className={`
                    bg-white h-screen fixed top-0 left-0 z-10 shadow-sm transition-all duration-300
                    border-[2px] border-gray-100
                    flex flex-col gap-3
                `}
                style={{ width: `${sidebarWidth}px` }}
            >
                {/* Header with logo and toggle button */}
                <div className="flex items-center justify-between px-2 py-4">
                    <img
                        src={EderSparkLogo}
                        alt="EderSpark Logo"
                        className={`transition-all duration-300 ${
                            isSidebarOpen ? 'w-36 h-7' : 'hidden'
                        }`}
                    />
                    <button
                        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                        className="p-1 transition-all duration-300"
                    >
                        {isSidebarOpen ? (
                            <ChevronLeftIcon className="h-6 w-6 transition-all duration-300" />
                        ) : (
                            <ChevronRightIcon className="h-6 w-6 transition-all duration-300" />
                        )}
                    </button>
                </div>
                <SidebarMenu />
            </div>
        </>
    )
}