import { Container, HStack, Icon, Link, Stack, Box } from '@chakra-ui/react'
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { SiGithub, SiLinkedin, SiX } from 'react-icons/si'
import { AiFillInstagram } from "react-icons/ai";
import { Copyright } from './Copyright.tsx'
import EdersparkLogo from "../assets/ederspark-logo.png";


const Footer = () => (
  <Box as="footer" py={{ base: '10', md: '12' }} bg="gray.50" padding="10">
    <Stack gap="6">
      <Stack direction="row" justify="space-between" align="center">
        <img src={EdersparkLogo} alt="Ederspark Logo" width='100px' />
        <HStack gap="4">
          {socialLinks.map(({ href, icon }, index) => (
            <Link key={index} href={href} colorPalette="gray">
              <Icon size="md">{icon}</Icon>
            </Link>
          ))}
        </HStack>
      </Stack>
      <div
        className="flex flex-row w-full justify-center gap-6"
      >
          <a
            className="text-gray-800 hover:text-gray-500 hover:underline"
            href="/?page=terms"
          >
            Terms of Service
          </a>
          <a
            className="text-gray-800 hover:text-gray-500 hover:underline"
            href="/?page=cookie-policy"
          >
            Cookie Policy
          </a>
          <a
            className="text-gray-800 hover:text-gray-500 hover:underline"
            href="/?page=privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            className="text-gray-800 hover:text-gray-500 hover:underline"
            href="/?page=disclaimer"
          >
            Legal Disclaimer
          </a>
      </div>
      <Copyright />
    </Stack>
  </Box>
)

const socialLinks = [
  { href: 'https://x.com/EderSpark', icon: <SiX /> },
  { href: 'https://www.tiktok.com/@ederspark', icon: <FaTiktok /> },
  { href: 'https://www.linkedin.com/company/ederspark', icon: <SiLinkedin /> },
  { href: 'https://www.instagram.com/ederspark/', icon: <AiFillInstagram /> },
]

export default Footer