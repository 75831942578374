import React from "react";
import { Box, Heading, Stack, Group, Text, Spacer } from "@chakra-ui/react";
import { Button } from "../components/ui/button";
import {
  StepsCompletedContent,
  StepsContent,
  StepsItem,
  StepsList,
  StepsNextTrigger,
  StepsPrevTrigger,
  StepsRoot,
} from "../components/ui/steps";

import { Tabs } from "@chakra-ui/react"
import { LuCheckSquare, LuFolder, LuUser } from "react-icons/lu";

import SparkAILogoFull from "../assets/sparkai_new_logo.png";
import FocusLogoFull from "../assets/focus_logo_upscaled.png";
import SparkQueryLogoFull from "../assets/sparkquery_logo_upscaled.png";

const HowItWorksSparkAI = () => {
  return (
    <Box
      as="section"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      height="full" // Full viewport height
      px={{ base: "6", md: "12" }}
      bg="gray.50"
      textAlign="center"
    >
      {/* Steps Container */}
      <Text fontSize="xl" color={"gray.500"} mb={30}>
        Meet SparkAI, your AI research assistant! With access to over 200 million scientific papers, SparkAI helps you find answers and insights fast. Just ask, and let the power of AI and vast academic knowledge guide your discoveries!
      </Text>
      <Box>
        {/* Section Title */}
        <Heading as="h2" fontSize={{ base: "2xl", md: "4xl" }} mb="8" color="gray.800">
          How It Works
        </Heading>

        {/* Steps */}
        <StepsRoot
          orientation="vertical"
          height="400px"
          defaultValue={1}
          count={4}
          width={{ base: "100%", md: "650px" }}
        >
          <StepsList>
            <StepsItem index={0} title="Enter a message" />
            <StepsItem index={1} title="Get a detailed response" />
            <StepsItem index={2} title="See references" />
            <StepsItem index={3} title="Follow-up" />
          </StepsList>

          <Stack mt="6" width={{base: "300px", md: "450px"}}>
            <StepsContent index={0}>
              Ask SparkAI a question about any topic you are interested in.
            </StepsContent>
            <StepsContent index={1}>
              Our model will provide you with a detailed response to your question, citing and referencing relevant papers.
            </StepsContent>
            <StepsContent index={2}>
              Explore the references and dive deeper into the papers that SparkAI has cited.
            </StepsContent>
            <StepsContent index={3}>
              Follow up with more questions or explore related topics to continue your research journey.
            </StepsContent>
            <StepsCompletedContent>
              All steps are complete! You're ready to transform your research workflow.
            </StepsCompletedContent>

            <Group mt="4">
              <StepsPrevTrigger asChild>
                <Button variant="outline" size="sm">
                  Prev
                </Button>
              </StepsPrevTrigger>
              <StepsNextTrigger asChild>
                <Button variant="outline" size="sm">
                  Next
                </Button>
              </StepsNextTrigger>
            </Group>
          </Stack>
        </StepsRoot>
      </Box>
    </Box>
  );
};

const HowItWorksFocus = () => {
  return (
    <Box
      as="section"
      display="flex"
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
      height="full" // Full viewport height
      px={{ base: "6", md: "12" }}
      bg="gray.50"
      textAlign="center"
    >
      {/* Steps Container */}
      <Text fontSize="xl" color={"gray.500"} mb={30}>
      Introducing Focus, the streamlined spin-off from SparkAI that lets you dive deep into a single scientific article like never before. Forget distractions—Focus provides an immersive experience, helping you dissect, understand, and engage with every detail of the paper that matters to you. Whether it's for study, research, or simply curiosity, Focus empowers you to get the most out of your reading, efficiently and effectively.
      </Text>
      <Box>
        {/* Section Title */}
        <Heading as="h2" fontSize={{ base: "2xl", md: "4xl" }} mb="8" color="gray.800">
          How It Works
        </Heading>

        {/* Steps */}
        <StepsRoot
          orientation="vertical"
          height="400px"
          defaultValue={1}
          count={4}
          width={{ base: "100%", md: "650px" }}
        >
          <StepsList>
            <StepsItem index={0} title="Find an interesting research paper" />
            <StepsItem index={1} title="Ask about anything you want to know" />
            <StepsItem index={2} title="Get just the information you need" />
            <StepsItem index={3} title="Follow up with more questions" />
          </StepsList>

          <Stack mt="6" width={{base: "300px", md: "450px"}}>
            <StepsContent index={0}>
              Find a research paper that interests you and open it in Focus through SparkAI's references or SparkQuery.
            </StepsContent>
            <StepsContent index={1}>
              Ask Focus any questions you have about the paper, and it will provide you with the information you need.
            </StepsContent>
            <StepsContent index={2}>
              Get just the information you need from the paper, without any distractions or unnecessary details.
            </StepsContent>
            <StepsContent index={3}>
              Follow up with more questions or explore related topics to continue your research journey.
            </StepsContent>
            <StepsCompletedContent>
              All steps are complete! You're ready to transform your research workflow.
            </StepsCompletedContent>

            <Group mt="4">
              <StepsPrevTrigger asChild>
                <Button variant="outline" size="sm">
                  Prev
                </Button>
              </StepsPrevTrigger>
              <StepsNextTrigger asChild>
                <Button variant="outline" size="sm">
                  Next
                </Button>
              </StepsNextTrigger>
            </Group>
          </Stack>
        </StepsRoot>
      </Box>
    </Box>
  );
};

const HowItWorksSparkQuery= () => {
  return (
    <Box
    as="section"
    display="flex"
    flexDirection={"column"}
    alignItems="center"
    justifyContent="center"
    height="full" // Full viewport height
    px={{ base: "6", md: "12" }}
    bg="gray.50"
    textAlign="center"
  >
      {/* Steps Container */}
      <Text fontSize="xl" color={"gray.500"} mb={30}>
      Discover SparkQuery, the scientific search engine that understands you. Simply type your question in natural language, and SparkQuery quickly sifts through millions of papers to provide insights on which ones could be most relevant to your research. Say goodbye to hours of filtering and let SparkQuery do the heavy lifting, so you can focus on what truly matters—your discoveries.  
      </Text>
      <Box>
        {/* Section Title */}
        <Heading as="h2" fontSize={{ base: "2xl", md: "4xl" }} mb="8" color="gray.800">
          How It Works
        </Heading>

        {/* Steps */}
        <StepsRoot
          orientation="vertical"
          height="400px"
          defaultValue={1}
          count={4}
          width={{ base: "100%", md: "650px" }}
        >
          <StepsList>
            <StepsItem index={0} title="Search in natural languague" />
            <StepsItem index={1} title="Get a list of relevant papers" />
            <StepsItem index={2} title="Quickly decide which papers is relevant for you" /> 
            <StepsItem index={3} title="Take it to the next level with Focus" />
          </StepsList>

          <Stack mt="6" width={{base: "300px", md: "450px"}}>
            <StepsContent index={0}>
              Type your question in natural language and let SparkQuery do the rest.
            </StepsContent>
            <StepsContent index={1}>
              SparkQuery will provide you with a list of papers that are most relevant to your query.
            </StepsContent>
            <StepsContent index={2}>
              Quickly decide which papers are most relevant to your research and dive deeper into them.
            </StepsContent>
            <StepsContent index={3}>
              Take your research to the next level by exploring the papers in Focus for a more detailed analysis.
            </StepsContent>
            <StepsCompletedContent>
              All steps are complete! You're ready to transform your research workflow.
            </StepsCompletedContent>

            <Group mt="4">
              <StepsPrevTrigger asChild>
                <Button variant="outline" size="sm">
                  Prev
                </Button>
              </StepsPrevTrigger>
              <StepsNextTrigger asChild>
                <Button variant="outline" size="sm">
                  Next
                </Button>
              </StepsNextTrigger>
            </Group>
          </Stack>
        </StepsRoot>
      </Box>
    </Box>
  );
};


const HowItWorks = () => {
  return (
    <div
      className="flex flex-col items-center justify-center bg-gray-50 text-center w-full pt-8 px-6 md:pt-16 shadow-md"
    >
        <Tabs.Root defaultValue="sparkai" variant="plain">
          <Tabs.List bg="bg.muted" rounded="l3" p="3" gap="6">
            <Tabs.Trigger value="sparkai" p="3">
              <img src={SparkAILogoFull} alt="SparkAI Logo" width="100" />
            </Tabs.Trigger>
            <Tabs.Trigger value="focus" p="3">
              <img src={FocusLogoFull} alt="Focus Logo" width="100" />
            </Tabs.Trigger>
            <Tabs.Trigger value="sparkquery" p="3">
              <img src={SparkQueryLogoFull} alt="SparkQuery Logo" width="100" />
            </Tabs.Trigger>
            <Tabs.Indicator rounded="l2" />
          </Tabs.List>
          <Tabs.Content value="sparkai">
            <HowItWorksSparkAI />
          </Tabs.Content>
          <Tabs.Content value="focus">
            <HowItWorksFocus />
          </Tabs.Content>
          <Tabs.Content value="sparkquery">
            <HowItWorksSparkQuery />
          </Tabs.Content>
        </Tabs.Root>
      </div>
      )
}
export default HowItWorks;
