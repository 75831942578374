import React from 'react'
import BaseCard from './BaseCard'
import ChatBubblesIcon from '../../icons/ChatBubblesIcon.png'
import ChatBubbleWithTextIcon from '../../icons/ChatBubbleWithTextIcon.png'
import ThreeUsersIcon from '../../icons/ThreeUsersIcon.png'
import { getNewDataThisMonth } from '../../utils/utils'


export default function HomePage() {

    const [ userData, setUserData ] = React.useState(null)
    const [ chatData, setChatData ] = React.useState(null)
    const [ messageData, setMessageData ] = React.useState(null)

    const [ userInfo, setUserInfo ] = React.useState(null)
    const [ chatInfo, setChatInfo ] = React.useState(null)
    const [ messageInfo, setMessageInfo ] = React.useState(null)

    const [ userIsLoading, setUserIsLoading ] = React.useState(true)
    const [ chatIsLoading, setChatIsLoading ] = React.useState(true)
    const [ messageIsLoading, setMessageIsLoading ] = React.useState(true)

    const [ dataMode, setDataMode ] = React.useState('all')

    React.useEffect(() => {
        const fetchUserData = async () => {
            setUserIsLoading(true)
            const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/users`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
                
            const user = await userResponse.json()
            setUserData(user)
            const userInfo = getNewDataThisMonth(user, new Date().getMonth())
            if (dataMode === 'all') {
                userInfo.number = user.length
                userInfo.label = 'Total users'
            } else {
                userInfo.label = 'New users this month'
            }
            setUserInfo(userInfo)
            setUserIsLoading(false)
        }
        const fetchChatData = async () => {
            setChatIsLoading(true)
            const chatResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/chats`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
            const chat = await chatResponse.json()
            setChatData(chat)
            const chatInfo = getNewDataThisMonth(chat, new Date().getMonth())
            if (dataMode === 'all') {
                chatInfo.number = chat.length
                chatInfo.label = 'Total chats'
            } else {
                chatInfo.label = 'New chats this month'
            }
            setChatInfo(chatInfo)
            setChatIsLoading(false)
        }
        const fetchMessageData = async () => {
            setMessageIsLoading(true)
            const messageResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
            const message = await messageResponse.json()
            setMessageData(message)
            const messageInfo = getNewDataThisMonth(message, new Date().getMonth())
            if (dataMode === 'all') {
                messageInfo.number = message.length
                messageInfo.label = 'Total messages'
            } else {
                messageInfo.label = 'New messages this month'
            }
            setMessageInfo(messageInfo)
            setMessageIsLoading(false)
        }
        fetchUserData()
        fetchChatData()
        fetchMessageData()
    }, [dataMode])


    return (
        <div
            className='w-full h-full flex flex-col gap-10'
        >
            <div
                className='flex flex-row gap-4'
            >
                <button
                    className={`
                        dataModeButton
                        ${dataMode === 'all' ? 'dataModeButtonActive' : ''}
                        `}
                    onClick={() => setDataMode('all')}
                >
                    All time
                </button>
                <button
                    className={`
                        dataModeButton
                        ${dataMode === 'month' ? 'dataModeButtonActive' : ''}
                        `}
                    onClick={() => setDataMode('month')}
                >
                    This month
                </button>
            </div>
            <div
                className='w-full h-full grid grid-cols-1 md:grid-cols-2 gap-10'
            >
                <BaseCard label={userInfo?.label} number={userInfo?.number} percentage={userInfo?.percentage} targetPage='users' icon={ThreeUsersIcon} is Loading={userIsLoading} />
                <BaseCard label={chatInfo?.label} number={chatInfo?.number} percentage={chatInfo?.percentage} targetPage='chats' icon={ChatBubblesIcon} isLoading={chatIsLoading} />
                <BaseCard label={messageInfo?.label} number={messageInfo?.number} percentage={messageInfo?.percentage} targetPage='messages' icon={ChatBubbleWithTextIcon} isLoading={messageIsLoading} />
            </div>
        </div>
    )
}