import React from "react";
import { Box, Flex, Heading, Text, Button, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import IlustrationHero from "../assets/IlustrationHero.png";

const MotionBox = motion(Box); // Framer Motion applied to Box
const MotionHeading = motion(Heading); // Framer Motion applied to Heading

const HeroSection = () => {
  return (
    <div
      className="flex flex-col items-center justify-center gap-4 bg-orange-50 text-center w-full py-10 px-6 md:py-40 mb-4 rounded-xl shadow-md"
    >
      {/* Animated Heading */}
      <MotionHeading
        as="h1"
        fontWeight="bold"
        mb="4"
        lineHeight="short"
        color="gray.800"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }} // Increased font size
      >
        A platform that{" "}
        <text 
          className="text-orange-400"
        >
          accelerates
        </text>{" "}
        your research and{" "}
        <text
          className="text-orange-400"
        >
          enhances
        </text>{" "}
        your results.
      </MotionHeading>

      {/* Animated Subtitle */}
      <MotionBox
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <text
          className="text-lg text-gray-600"
        >
          We provide cutting-edge tools and resources to streamline your
          research processes, allowing you to focus on impactful discoveries
          and drive innovation.
        </text>
      </MotionBox>

      {/* Animated Buttons */}
      <MotionBox
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, delay: 0.5 }}
      >
        <div
          className="flex flex-col sm:flex-row sm:justify-center gap-2"
        >
          <button
            className="text-white font-semibold py-2 px-4 rounded-lg shadow-md bg-gray-800 hover:bg-gray-700"
            // Travel to "features" section
            onClick={() =>
              document.getElementById("features").scrollIntoView({ behavior: "smooth" })
            }
          >
            Learn More
          </button>
          <button
            className="text-gray-800 font-semibold py-2 px-4 rounded-lg shadow-md bg-orange-200 border-2 border-orange-400 hover:bg-orange-50"
            onClick={() => window.open("https://ederspark.com/?page=platform", "_self")}
          >
            Try our platform
          </button>
        </div>
      </MotionBox>
    </div>
  );
};

export default HeroSection;
