import React from "react";
import { Box, Flex, Text, Image } from "@chakra-ui/react";

// Import your logos
import UcoLogo from "../assets/ucoLogo.png";
import FacCiencias from "../assets/facCiencias.png";
import ElPatioLogo from "../assets/el-patio-completo-logo.png";
import SemanticScholarLogo from "../assets/Semantic_Scholar_logo.svg.png";

const TrustedLogos = () => {
  return (
    <div
      className="py-12 bg-gray-50 text-center"
    >
      {/* Title */}
      <text
        className="text-lg text-gray-500"
      >
        Trusted by
      </text>

      {/* Logos */}
      <div
        className="flex justify-center items-center gap-8 flex-wrap max-w-7xl mx-auto"
        justify="center"
        align="center"
        gap="8"
        wrap="wrap"
        maxW="1200px"
        mx="auto"
      >
        {[
            { src: ElPatioLogo, alt: "AOF El Patio Logo", height: "100px" },
            { src: SemanticScholarLogo, alt: "Semantic Scholar Logo", height: "60px" },
        ].map((logo) => (
          <Image
            key={logo.alt}
            src={logo.src}
            alt={logo.alt}
            height={logo.height}
            filter="grayscale(100%)" // Apply grayscale filter
            // Apply hover effect grayscale(0%) and scale(1.2)
            _hover={{ filter: "grayscale(0%)", transform: "scale(1.2)" }}
          />
        ))}
      </div>
    </div>
  );
};

export default TrustedLogos;
