import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Skeleton } from '@mui/joy';


const columns = [
    { field: 'message_id', headerName: 'Message ID', width: 120 },
    { field: 'conversation_id', headerName: 'Conversation ID', width: 130, editable: false },
    { field: 'content', headerName: 'Content', width: 350, editable: false },
    { field: 'num_chars', headerName: 'Lenght', width: 100, editable: false },
    { 
        field: 'role_id',
        headerName: 'Role',
        width: 150,
        editable: false,
        valueGetter: (value, row) => {
            return row?.role_id === 1 ? "User" : "Model"
        }
    },
    { 
        field: 'messaged_at', 
        headerName: 'Messaged At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.messaged_at ? new Date(row.messaged_at).toUTCString() : null
        }
    },
    { 
        field: 'updated_at', 
        headerName: 'Updated At', 
        width: 180, 
        valueGetter: (value, row) => {
            return row?.updated_at ? new Date(row.updated_at).toUTCString() : null
        } 
    }
  ];

export default function MessagesPage() {
    const [rows, setRows] = React.useState([])
    const [isTableLoading, setIsTableLoading] = React.useState(true)

    React.useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/messages`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY,
                    'Authorization': 'c46801db-29ee-408c-bf24-6b16ba8131ac'
                }
            })
            const data = await response.json()
            const rows = data.map((row, index) => {
                return {
                    id: index,
                    ...row
                }
            })
            console.log("Rows: ", rows)
            setRows(rows)
            setIsTableLoading(false)
        }
        fetchData()
    }, [])

    return (
        <>
        { isTableLoading ?
            <div className='w-full h-full flex justify-center items-center'>
                <Skeleton variant="rectangular" width={1200} height={600} />
            </div>
            : 
            <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },
                sorting: {
                    sortModel: [{ field: 'updated_at', sort: 'desc' }], // Replace 'id' with the name of the column
                },
            }}
            pageSizeOptions={[15]}
            checkboxSelection
            disableRowSelectionOnClick
            />
        }
        </>
    )
}