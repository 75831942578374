import React from "react";
import { Box, Flex, Heading, Text, VStack, Icon, SimpleGrid } from "@chakra-ui/react";
import { FaSearch, FaRegLightbulb, FaShareAlt, FaLaptopCode } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs";
import { ComputerDesktopIcon, LightBulbIcon, ShareIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";


const KeyFeatures = () => {
  return (
    <div
      className="bg-white py-16 px-6 md:px-12 text-center flex flex-col gap-8 w-full"
    >
      {/* Section Title */}
      <text
        className="text-3xl md:text-4xl text-gray-800"
        // add an anchor link to the section
        id="features"
      >
        Key Features
      </text>

      {/* Features Grid */}
      <div
        className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-4 px-8"
      >
        {/* Feature 1 */}
        <VStack spacing="4" textAlign="center">
          <ComputerDesktopIcon
            className="h-10 w-10 text-orange-500"
          />
          <Heading as="h3" fontSize="xl" color="gray.700">
            AI-Powered Search
          </Heading>
          <Text fontSize="md" color="gray.600">
            Find the most relevant academic articles quickly and easily with AI-driven precision.
          </Text>
        </VStack>

        {/* Feature 2 */}
        <VStack spacing="4" textAlign="center">
          <LightBulbIcon
            className="h-10 w-10 text-orange-500"
          />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Intelligent Automatization
          </Heading>
          <Text fontSize="md" color="gray.600">
            Transform lengthy papers into concise, actionable insights in seconds.
          </Text>
        </VStack>

        {/* Feature 3 */}
        <VStack spacing="4" textAlign="center">
          <MagnifyingGlassIcon
            className="h-10 w-10 text-orange-500"
          />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Improve your research workflow
          </Heading>
          <Text fontSize="md" color="gray.600">
            Save time and effort by letting us handle the heavy lifting for you.
          </Text>
        </VStack>

        {/* Feature 4 */}
        <VStack spacing="4" textAlign="center">
          <ShareIcon
            className="h-10 w-10 text-orange-500"
          />
          <Heading as="h3" fontSize="xl" color="gray.700">
            Take advantage of techonology
          </Heading>
          <Text fontSize="md" color="gray.600">
            Leverage cutting-edge technology to stay ahead of the curve.
          </Text>
        </VStack>
      </div>
    </div>
  );
};

export default KeyFeatures;
