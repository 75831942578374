import React from 'react';
import Sidebar from "../components/dashboard/Sidebar";
import Layout from "../components/dashboard/Layout";
import '../styles/dashboard.css';


// Create context for dashboard
export const DashboardContext = React.createContext();

export default function Dashboard() {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)
    const [pageSelected, setPageSelected] = React.useState('dashboard')

    const providerValues = {
        isSidebarOpen,
        setIsSidebarOpen,
        pageSelected,
        setPageSelected
    }

    React.useEffect(() => {
        console.log("API Key: ", process.env)
    }, [])

    return (
        <DashboardContext.Provider value={providerValues}>
            <div
                className='bg-gray-50 w-full h-full flex flex-row'
            >
                <Sidebar />
                <Layout />
            </div>
        </DashboardContext.Provider>
    )
}